import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { OrbitvuPageDTO } from '@DTO/OrbitvuPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface OrbitvuPageProps {
  pageContext?: TemplatePageContextData;
}

const OrbitvuPage = ({ pageContext }: OrbitvuPageProps) => {
  const orbitvuPage = OrbitvuPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(orbitvuPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={orbitvuPage.metaData}
        breadcrumbs={orbitvuPage.breadcrumbs}
        stickedForm={orbitvuPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={orbitvuPage.langPath}
      >
        <PageComponents components={orbitvuPage.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default OrbitvuPage;
